<template>
    <div class="testimonials-w-container">
        <div class="testimonials-container text-center d-flex flex-column justify-content-center align-items-center pb-5">
            <h2 class="title mb-2">¿Por qué confiar en nosotros?</h2>
            <p class="sub-title w-75">
                Contamos con más de <strong style="color: #ff8f1d;">13 años en el mercado</strong>, operando con la solidez de Banco Azteca, hemos atendido a casi <strong style="color: #ff8f1d;">6 millones de clientes</strong> en más de <strong style="color: #ff8f1d;">1,100 sucursales</strong> de todo el país, abriendo los <strong style="color: #ff8f1d;">365 días del año</strong> en un amplio horario.
                <br>
                Nuestros clientes son nuestro mejor respaldo.
            </p>
            <div class="testimonials slider-blog mb-5">
                <VueSlickCarousel :arrows="true" :dots="true" v-bind="settings" @afterChange="trackClick('testimoniales', 'Scroll testimonios', 'scroll')">
                    <div class="testimonial slide-content mb-5" v-for="(testimonial, index) in testimonialesData"
                         :key="index">
                        <div class="testimonial-media">
                            <img loading="lazy" width="200" height="200" v-if="testimonial.values.asset_testimonio.value"
                            :src="testimonial.values.asset_testimonio.value.public_path"
                            :alt="testimonial.values.asset_testimonio.value.alt" />
                            <div class="h-100 d-flex align-items-center" :style="'text-align: initial;'" v-else>
                                <lite-vimeo :videoid="testimonial.values.url_video.value" videotitle="This is a video title"></lite-vimeo>
                            </div>
                        </div>
                        <div class="testimonio">
                            <p>
                                <img v-img-prefix class="comillas mt-4" :src="`/public-assets/comillas-azules`"
                                     alt="comillas azules icono" width="30" height="28"/>
                            </p>
                            <p class="sub-title my-4">
                                {{ testimonial.values.testimonio.value }}
                            </p>
                        </div>
                        <div class="client-info">
                            <p class="m-auto"><strong>{{ testimonial.values.nombre_cliente.value }}</strong></p>
                            <span class="sub-title m-auto pb-5"><em>{{ testimonial.values.ocupacion.value }}</em></span>
                        </div>
                    </div>
                </VueSlickCarousel>
            </div>
        </div>
    </div>
</template>

<script>
import '@slightlyoff/lite-vimeo';
import { trackClick } from "@/helpers/clickTracker";
export default {
    methods: {trackClick},
    props: { testimonialesData: Array },
    data() {
        return {
            settings: {
                "dots": true,
                "infinite": false,
                "slidesToShow": 3,
                "focusOnSelect": true,
                "initialSlide": 0,
                "edgeFriction": 0.35,
                "responsive": [
                    {
                        "breakpoint": 1050,
                        "settings": {
                            "slidesToShow": 2.5,
                        }
                    },
                    {
                        "breakpoint": 900,
                        "settings": {
                            "slidesToShow": 2,
                        }
                    },
                    {
                        "breakpoint": 700,
                        "settings": {
                            "slidesToShow": 1.5,
                            "slidesToScroll": 2,
                            "arrows": false
                        }
                    },
                    {
                        "breakpoint": 520,
                        "settings": {
                            "slidesToShow": 1,
                            "slidesToScroll": 1,
                            "arrows": false,
                            "infinite": true
                        }
                    }
                ]
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.testimonials-w-container {
    background: #f6f6f6;
    width: 100%;

}
.testimonials-container {
    max-width: 1400px;
    margin: auto;
}

.testimonials {
    width: 90%;
    max-width: 2000px;
}

.slider-blog {
    display: block !important;
}

.testimonial {
    background: white;
    min-width: 300px;
    width: 90% !important;
    height: 570px !important;

    p {
        width: 90%;
        text-align: left;
        margin: auto;
    }

    span {
        width: 90%;
        text-align: left;
    }
}

.testimonial-media {
    position: relative;
    background: #e7eaf0;
    height: 15vw;

    img {
        border-radius: 70%;
        object-fit: cover;
        max-width: 80%;
        width: auto;
        height: 100%;
        margin: auto;
        transform: translateY(10%);

    }

    .display-image {
        transform: translateY(15%);
    }
}

@media (min-width: 1000px) {
    .testimonial-media {
        height: 250px;
    }
    .testimonial {
        height: 550px !important;
    }
}

@media (max-width: 1000px) {
    .testimonial-media {
        height: 300px;
    }
    .testimonio{
        height: 170px !important;
    }
}

@media (max-width: 900px) {
    .testimonial {
        height: 600px !important;
    }
    .testimonio{
        height: 201px !important;
    }

}

@media (max-width: 800px) {
    .testimonial-media {
        height: 30vw;
    }
    .testimonio{
        height: 225px !important;
    }
}

@media (max-width: 600px) {
    .testimonial-media {
        height: 200px;
    }
    .testimonio{
        height: 225px !important;
    }
}
.testimonio{
    height: 200px;
}

.comillas {
    width: 30px;
    align-self: flex-start;
}

.slick-track {
    display: flex !important;
}

.slick-slide {
    height: inherit !important;
}
</style>