import Vue from 'vue'
// Packages
import VueSlickCarousel from 'vue-slick-carousel'

// Register components
Vue.component('VueSlickCarousel', VueSlickCarousel)

// Try to put here all the global filters for the components
Vue.filter("numberToMoney", value => {
	return value ? `$${value.toLocaleString("en-US").replace('.00', '')}` : '$0.0';
});

// Components
import Banner from '../components/main/Banner.vue'
import Blog from '../components/main/Blog.vue'
import Testimonials from '../components/main/Testimonials.vue'

document.addEventListener('DOMContentLoaded', () => {
	new Vue({
		render: h => h(Banner, {
			props: {
				bannersData: banners
			}
		})
	}).$mount('#banner')
	
	new Vue({
		render: h => h(Blog, {
			props: {
				publicationsData: publications
			}
		})
	}).$mount('#blog')
	
	new Vue({
		render: h => h(Testimonials, {
			props: {
				testimonialesData: testimoniales
			}
		})
	}).$mount('#testimoniales')
})